import React from "react";
import Layout from "../components/Layout/Layout";
import Seo from "../components/seo";
import { graphql } from "gatsby";
import PageHero from "../components/PageHero/PageHero";
import Partner from "../components/Partners/Partner";
import MasonryGrid from "../components/Masonry/MasonryGrid";

const partners = ({ data: { page } }) => {
  const { hero, subsection } = page.header;
  const partners = page.partner.partner;
  return (
    <Layout>
      <Seo
        title={hero.title}
        image={hero.image?.localFile?.childImageSharp.original?.src}
      />
      <PageHero
        title={hero.title}
        src={hero.image?.localFile}
        subtitle={subsection.subtitle}
        text={subsection.text}
      />
      <section
        className="border-t border-opacity-10 border-lightest"
        data-sal="fade"
        data-sal-duration="1000"
        data-sal-easing="ease"
      >
        <div className="container">
          <MasonryGrid title={""}>
            {partners.map((partner, index) => (
              <Partner key={index} partner={partner} />
            ))}
          </MasonryGrid>
        </div>
      </section>
    </Layout>
  );
};

export default partners;

export const pageQuery = graphql`
  query PartnersPage {
    page: wpPage(title: { eq: "Partners" }) {
      header {
        hero {
          title
          image {
            localFile {
              childImageSharp {
                original {
                  src
                }
                gatsbyImageData(
                  quality: 100
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
        subsection {
          subtitle
          text
        }
      }
      partner {
        partner {
          namn
          text
          link
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  width: 200
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`;
