import React from 'react'
import FluidImage from '../FluidImage/FluidImage'
import './Partner.scss'

const Partner = ({ partner }) => {
  return (
    <div className="partner masonry-item">
      <div className="header">
        <FluidImage contain src={partner.logo.localFile} title={partner.namn} />
      </div>
      <div className="body">
        <h3 className="h4">{partner.namn}</h3>
        <p>{partner.text}</p>
      </div>
      <div className="footer">
        <a href={partner.link} target={'_blank'} rel="noreferrer">
          Hemsida
        </a>
      </div>
    </div>
  )
}

export default Partner
